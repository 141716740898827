// Whoever is looking at this project.  I am so sorry
// I am not a frontend developer and React is not my thang.


export const PAGE_STYLE = {
    background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(185,206,222,1) 100%)",
    minHeight: "85vh",
    paddingLeft: "65px",
    paddingRight: "65px",
    paddingBottom: "100px",
    paddingTop: "50px"
}

// #b9cede